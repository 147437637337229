import $ from 'jquery';
import 'jquery-backstretch';

$(function () {
// Set slider image as background
// variable is set in local/thmpartner/Resources/Private/Templates/Content/Slider.html
    if (typeof backgroundImage !== 'undefined') {
        $('div.container-fluid.container-fluid--main').backstretch(backgroundImage);
    }

    // Set the classes for login-box, depending on existing of a form or an alert within the login-box.
    // Needed for styling of edge cases.
    if ($('.card--login').find('form').length === 0) {
        $('.login-box').addClass('login-box--has-no-form');
    }

    if ($('.card--login').find('.alert').length) {
        $('.login-box').addClass('login-box--has-alert');
    }
});
